import React, { useEffect, useState } from "react";

const introduction = {
  "center super admin": {
    heading: "Stay Tuned for Amazing and Innovative Services Coming in the next 12 months",
    benefits: [
      { title: "PrOffer: ", text: "Will allow centers to offer individualized pricing to parents for any vacant slots." },
      { title: "Foray: ", text: "Software totally redesigned from the ground up which is laser-focused on operations. Sit back and relax while Foray essentially runs the center for you." },
      { title: "Adventures: ", text: "Hang out with Enzo and Leila and many others and join them in their trials and tribulations. Do you see yourself in any of these characters?" },
    ],
    exclamation: "Lots more coming over the next two years!!"
  },
  teacher: {
    heading: "Teachers are the best!",
    statement: "We hope you are enjoying the convenience of Medical Evaluations ONLINE!",
    benefits: [
      { title: "Blogs: ", text: "There are many blogs that will help you navigate the challenges of being a teacher ... check them out!" },
      { title: "Adventures: ", text: "Hang out with Enzo and Leila and many others and join them in their trials and tribulations. Do you see yourself in any of these characters?" },
    ],
  },
  parent: {
    heading: "Child Care is challenging!  We are making it easier 😊",
    statement: "Check out some of our offerings:",
    benefits: [
      { title: "Blogs - ", text: "There are many questions that might arise. Our blogs answer most of them" },
      { title: "Proffer - ", text: "A new service starting in the next 12 months which allows you to individualize the cost of tuition with your Center. You will love it! Stay Tuned!" },
      { title: "Adventures - ", text: "Ever wonder what happens in a Child Care center all day long? Wonder no more! Hang out with Enzo and Leila and many others and join them in their trials and tribulations." },
    ],
  },
};

interface Props {
  proceedingRole: string;
}
const UnlockExclusiveBenefits: React.FC<Props> = ({ proceedingRole }) => {
  const [info, setInfo] = useState<any>()

  useEffect(() => {
    setInfo(() => {
      switch (proceedingRole) {
        case "center super admin":
          return introduction["center super admin"]
        case "teacher":
          return introduction["teacher"]
        case "parent":
          return introduction["parent"]
        default:
          return introduction["center super admin"]
      }
    })
  }, [proceedingRole])

  return (
    <>
      <div className="bg-white rounded-xl py-5 md:py-12 px-5 md:px-9 flex flex-col items-start gap-4 md:gap-6">
        <h2 className="text-xl lg:text-2xl text-secondary font-semibold ">
          {info?.heading}{" "}
        </h2>
        {info?.statement &&
          <p className="text-base text-secondaryVariant ">
            {info?.statement}
          </p>
        }
        {info?.benefits?.map((benefit: any) => (
          <p className="text-base text-secondaryVariant ">
            <strong className="font-medium">{benefit?.title}</strong>
            {benefit?.text}
          </p>
        ))}
        {info?.exclamation &&
          <p className="text-base text-secondaryVariant font-medium ">
            {info?.exclamation}
          </p>
        }
      </div>
    </>
  );
};

export default UnlockExclusiveBenefits;
